import Layout from '../components/Layout';
import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { DefaultStyledSystemProps } from './../types/Common';
import mq from './../theme/media';

import notFoundImage from './../img/404.png';


const NotFoundPage = () => (
  <Layout>
    <div className="u-txt-c">
      <div>
        <NotFoundWrap>
          <h2><img src={notFoundImage} alt="" /></h2>
        </NotFoundWrap>
        <p className='u-pc-fz20 u-sp-fz16 u-txt-red u-fw-b'>404 Page not found</p>

        <section className="u-pc-mt50 u-sp-mt35">
          <h3 className='u-pc-fz24 u-sp-fz20 u-fw-b'>申し訳ございません。<br />ページが見つかりませんでした。</h3>
          <p className='u-pc-fz18 u-sp-fz15 u-pc-mt30 u-sp-mt15'>お探しのページは存在しないか、URLが変更されている可能性があります。<br />また、URLにタイプミスがないか再度ご確認ください。</p>
          <p className='u-pc-fz18 u-sp-fz15 u-pc-mt30 u-sp-mt15'><span className="u-pc-fz18 u-sp-fz15">I'm sorry. Oops Page Not Found</span><br />The page you are looking for does not exist or has been moved.</p>
          <div className="c-button -border-button u-dis-i u-txt-c u-pc-mt40 u-sp-mt20">
            <div className="c-button__inner">
              <Link
                to={'/'}
                className="c-button__text u-sp-w100p"
              >
                トップへ戻る（Go to Top）
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  </Layout>
);

export const NotFoundWrap = styled.div<DefaultStyledSystemProps>`
  width: 100%;
  max-width: 400px;
  margin: 50px auto 20px;

  ${mq.down`
    width: 100%;
    max-width: 80%;
  `}

  img {
    display: block;
    width: 100%;
  }
`;

export default NotFoundPage;
